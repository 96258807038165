import React from "react"
import { Link, graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

// Style
import "../style/main.scss"

// Components
import Layout from "../components/layout"
import Reference from "../components/home/reference"
import SEO from "../components/seo"
import smoothScroll from '../utils/smoothScroll';

// Data
import references from "../data/references.json"
import ShortPost from "../components/blog-post/shortPost"

// Icons
import messageSentIcon from "../images/sent-icon.svg";
import errorIcon from "../images/error-icon.svg";

const findReferenceImage = (imageName, data) => {
  return data.find((img) =>
    img.node.childImageSharp.gatsbyImageData.images.fallback.src.includes(
      imageName
    )
  )
}

class IndexPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      contactFormIsSending: false,
      contactFormSentOk: null,
      skillsExpanded: false
    }
    this.expandSkills = this.expandSkills.bind(this)
    this.submitContactForm = this.submitContactForm.bind(this)
    this.closePopup = this.closePopup.bind(this)
  }

  expandSkills(e) {
    e.preventDefault()
    this.setState({ skillsExpanded: true })
  }

  // CONTACT FORM
  /**
   * Send message
   * @param {*} e
   */
  submitContactForm(e) {
    const form = e.target
    e.preventDefault()
    this.setState({ contactFormIsSending: true })
    const formData = new FormData(form)
    fetch(
      "https://api.matthieufesselier.com/wp-json/contact-form-7/v1/contact-forms/5/feedback",
      {
        method: "POST",
        body: formData,
      }
    ).then((res) => {
      this.setState({
        contactFormIsSending: false,
        contactFormSentOk: res.ok,
      })
      if (res.ok) form.reset()
    })
  }

  /**
   * Close confirmation popup
   */
  closePopup() {
    this.setState({ contactFormSentOk: null })
  }

  render() {
    return (
      <Layout classes="home">
        <SEO type="home" />
        <div className="container mf-landing">
          <div className="mf-landing__inner">
            <h2>
              Bonjour, je suis{" "}
              <span className="text-primary">Matthieu Fesselier</span>
            </h2>
            <h1 className="mb-3 mt-3 mf-landing__title mf-title-underscore">
              <span>Développeur web</span>
            </h1>

            <p className="mt-3 mf-landing__intro">
              Freelance spécialisé dans le développement web front-end
            </p>

            <div className="mf-landing__buttons">
              <a onClick={smoothScroll} href="#expertise" className="mt-4 btn btn-outline-primary">
                En savoir plus
              </a>
            </div>

            <div className="mf-landing__illu">
              <StaticImage
                src="../images/matthieu-fesselier-illu.png"
                placeholder="blurred"
                layout="constrained"
                width={420}
                height={420}
                /* breakpoints={[210, 300, 420, 600, 840]} */
                sizes="(min-width:992px)420px,(min-width:576px)300px,210px"
                alt="Matthieu Fesselier - illustration"
              />
            </div>
          </div>
        </div>

        <div id="expertise" className="py-4 bg-light mf-expertise">
          <div className="my-5 container">
            <div className="h3 mt-5">Mes compétences</div>
            <p className="mb-5 lead">
              Le développement front-end est mon domaine d'expertise.
              J'interviens aussi régulièrement aux autres étapes de la
              production d'une application ou d'un site : UI&nbsp;design,
              développement&nbsp;back-end et SEO.
            </p>
            <div className="row">
              <div className="col-lg-4 order-lg-2 mf-expertise__item">
                <div className="mf-skill mf-skill--main">
                  <h2>
                    <span>Développement Front-End</span>
                  </h2>
                  <div className={"mf-skill__text" + (this.state.skillsExpanded ? 'd-block' : '')}>
                    <p>
                      Je vous accompagne dans le développement front-end de vos
                      applications web.
                    </p>
                    <p>
                      En fonction de vos besoins et de vos contraintes, je vous
                      conseille sur les technologies et les outils les plus
                      pertinents à mettre en place.
                    </p>
                    <p>
                      Spécialisé en JavaScript, j'utilise les techniques du web
                      moderne pour développer des applications performantes,
                      accessibles et maintenables dans la durée.
                    </p>
                  </div>
                  <p className="mf-skill__tags">
                    JavaScript - React - VueJS - React Native - WebComponents
                  </p>
                </div>
              </div>
              <div className="col-lg order-lg-1 mf-expertise__item">
                <div className="mf-skill">
                  <h2>
                    <span>UI Design</span>
                  </h2>
                  <div className={"mf-skill__text" + (this.state.skillsExpanded ? 'd-block' : '')}>
                    <p>Je conçois vos interfaces utilisateur.</p>
                    <p>
                      Concrètement, je crée les maquettes et les prototypes de
                      vos applications web, focalisé sur la création d'une
                      interface intuitive et fonctionnelle, d'une
                      hiérarchisation claire des contenus et d'un design
                      responsive.
                    </p>
                  </div>
                  <p className="mf-skill__tags">
                    Sketch - Invision - Adobe&nbsp;CC
                  </p>
                </div>
              </div>
              <div className="col-lg order-lg-3 mf-expertise__item">
                <div className="mf-skill">
                  <h2>
                    <span>Développement Back-End</span>
                  </h2>
                  <div className={"mf-skill__text" + (this.state.skillsExpanded ? 'd-block' : '')}>
                    <p>
                      Je développe des API, des back-offices simples, ou
                      j'interviens de manière ponctuelle sur la partie back-end
                      des applications.
                    </p>
                    <p>
                      Je suis donc en mesure d'anticiper les problèmes et de
                      faire le lien efficacement entre les équipes de
                      développement front-end et back-end d'un projet.
                    </p>
                  </div>
                  <p className="mf-skill__tags">
                    SOLID - Symfony - WordPress - Node.JS
                  </p>
                </div>
              </div>
              <div className="col-lg order-lg-4 mf-expertise__item">
                <div className="mf-skill">
                  <h2>
                    <span>SEO</span>
                  </h2>
                  <div className={"mf-skill__text" + (this.state.skillsExpanded ? 'd-block' : '')}>
                    <p>Je maîtrise les techniques fondamentales du SEO.</p>
                    <p>
                      J'optimise votre application pour améliorer son
                      référencement naturel (SEO on-site).
                    </p>
                    <p>
                      À l'aide des outils d'analyse de Google, je détecte les
                      freins et problèmes et propose des correctifs.
                    </p>
                  </div>
                  <p className="mf-skill__tags">
                    Google Analytics - Google Search Console
                  </p>
                </div>
              </div>
            </div>
            <div className="mt-5 text-center">
              <button
                onClick={this.expandSkills}
                className={"btn btn-outline-primary " + (this.state.skillsExpanded ? 'd-none' : '')}>
                Ok mais concrètement&nbsp;?
              </button>
            </div>
          </div>
        </div>

        <div id="references" className="mt-5 pt-5 container mf-references">
          <h3>Mes références</h3>
          <p className="mb-5 lead">Voici un aperçu de mes projets récents.</p>

          {references.map((reference) => (
            <Reference
              key={reference.title}
              data={reference}
              image={findReferenceImage(
                reference.image,
                this.props.data.referenceMockups.edges
              )}
            />
          ))}
        </div>

        <div id="blog" className="mb-5 py-5 mf-blog">
          <div className="container">
            <h3 className="mt-5">Mon blog</h3>
            <p className="lead mb-5">
              Découvrez une technologie ou comment résoudre un problème à
              travers ces courts articles basés sur mon expérience.
            </p>
            <div className="row">
              {this.props.data.allWpPost.nodes.map((node) => (
                <div key={node.id} className="col-md-6 col-lg-4 mf-blog__item">
                  <div className="mf-blog__post">
                    <ShortPost data={node} imageSize="small" />
                  </div>
                </div>
              ))}
              <div className="col-md-6 col-lg-4 mf-blog__item">
                <Link to={"blog"} className="mf-blog__post mf-blog__post--more">
                  <span>Voir tous les articles</span>
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div id="about" className=" my-5 py-5 container mf-about">
          <h3>À propos</h3>
          <p className="mb-5 lead">
            Quelques mots sur mon parcours et ma situation actuelle.
          </p>

          <div className="mf-about__content">
            <StaticImage
              src="../images/matthieu-fesselier-profile-picture.jpg"
              className="mf-about__image"
              placeholder="blurred"
              layout="constrained"
              width={300}
              height={300}
              /* breakpoints={[200, 300, 600]} */
              sizes="(min-width:992px) 300px,(max-width:768px) 200px,150px"
              alt="Matthieu Fesselier - Photo de profil"
            />

            <p>
              Diplômé d'un Master en Informatique, j'ai rapidement été passionné
              par le web et l'innovation continue des technologies qui le
              composent.
            </p>

            <p>
              Depuis une mission de 6 mois en Asie durant laquelle j'ai
              travaillé avec de nombreuses ONG, je mets mes talents au service
              de projets éducatifs et sportifs, et plus généralement à fort
              impact social.
            </p>

            <p>
              Aujourd'hui, être freelance me permet de travailler pour des
              projets qui me passionnent et de m'y investir à 200&nbsp;%.
            </p>
            <p>
              Aussi, je fais partie du collectif{" "}
              <a
                href="https://happy-dev.fr/fr/"
                target="_blank"
                rel="noreferrer"
                className="text-white">
                Happy Dev
              </a>
              , réseau des indépendants du numérique, qui me permet de concilier
              vie de freelance et travail en équipe.
            </p>

            <p>
              Si vous souhaitez en savoir plus sur mon parcours, consultez mon
              profil LinkedIn.
            </p>

            <a
              href="https://www.linkedin.com/in/matthieu-fesselier"
              className="mt-3 btn btn-outline-light"
              target="_blank"
              rel="noreferrer">
              Mon profil LinkedIn
            </a>
          </div>
        </div>

        <div id="contact" className="my-5 py-5 container">
          <div>
            <h3>Contact</h3>
            <p className="lead">
              Un projet, une idée, une question&nbsp;? C'est ici que ça se
              passe&nbsp;!
            </p>
          </div>

          <div className="mt-5 position-relative">
            {this.state.contactFormSentOk !== null && (
              <div
                className={`mf-contact-response mf-contact-response--${
                  this.state.contactFormSentOk ? "ok" : "error"
                }`}
                role="alertdialog"
                aria-live="polite">
                {this.state.contactFormSentOk === true && (
                  <div className="p-5">
                    <img src={messageSentIcon} width="100" height="100" className="d-inline-block mb-4" alt="" />
                    <div className="h4">Merci pour votre message&nbsp;!</div>
                    <p>Je reviendrai vers vous rapidement.</p>
                  </div>
                )}
                {this.state.contactFormSentOk === false && (
                  <div className="p-5">
                    <img src={errorIcon} width="100" height="100" className="d-inline-block mb-4" alt="" />
                    <div className="h4">Un problème est survenu.</div>
                    <p>Veuillez réessayer, ou m'écrire directement à contact[at]matthieufesselier.com</p>
                  </div>
                )}
                <button
                  onClick={this.closePopup}
                  className="btn btn-outline-light mb-4"
                >Fermer</button>
              </div>
            )}
            <form onSubmit={this.submitContactForm}>
              <div className="row">
                <div className="col-sm">
                  <div className="form-group">
                    <label className="required" htmlFor="name">
                      Votre nom
                    </label>
                    <input
                      id="name"
                      type="text"
                      name="your-name"
                      className="form-control"
                      required
                    />
                  </div>
                </div>

                <div className="col-sm">
                  <div className="form-group">
                    <label className="required" htmlFor="email">
                      Votre email
                    </label>
                    <input
                      id="email"
                      type="email"
                      name="your-email"
                      className="form-control"
                      required
                    />
                  </div>
                </div>
              </div>

              <div className="form-group">
                <label className="required" htmlFor="message">
                  Votre message
                </label>
                <textarea
                  id="message"
                  name="your-message"
                  rows="10"
                  className="form-control"
                  required></textarea>
              </div>

              <div className="mt-5 mb-5 text-center">
                <button
                  className="btn btn-outline-primary"
                  type="submit"
                  disabled={this.state.contactFormIsSending}>
                  {this.state.contactFormIsSending && (
                    <span
                      className="spinner-grow spinner-grow-sm mr-2 text-primary align-middle"
                      role="status"
                      aria-hidden="true"></span>
                  )}
                  Envoyer
                </button>
              </div>
            </form>
          </div>
        </div>
      </Layout>
    )
  }
}

export default IndexPage

export const pageQuery = graphql`
  {
    allWpPost(sort: { fields: [date], order: [DESC] }) {
      nodes {
        id
        title
        excerpt
        slug
        date
        categories{
          nodes{
            name
            slug
          }
        }
        details {
          gradient {
            startcolor
            endcolor
          }
          banner {
            localFile {
              publicURL
            }
          }
        }
      }
    }
    referenceMockups: allFile(
      filter: { relativeDirectory: { eq: "mockups" } }
    ) {
      edges {
        node {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, width: 350, height: 241)
          }
        }
      }
    }
  }
`
