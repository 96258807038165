import * as React from "react"

export default function PostImage({ startcolor, endcolor, imageSrc, title, size }) {
  const backgroundImageStyle = {
    backgroundColor: `${startcolor}`,
    background: `linear-gradient(130deg, ${startcolor} 0%, ${endcolor} 100%)`,
  }
  const sizes = {
    small: [286, 124],
    large: [700, 304]
  };

  return (
    <div className="post-image" style={backgroundImageStyle}>
      {imageSrc && <img src={imageSrc} width={sizes[size][0]} height={sizes[size][1]} alt={title} />}
    </div>
  )
}
