import * as React from "react"
import { Link } from "gatsby"

import PostImage from "./postImage"

export default function ShortPost({ data, imageSize, noClickTag }) {
  const category = data.categories.nodes[0];
  return (
    <article className="mf-short-post">
      <Link to={"/blog/" + data.slug}>
        <PostImage
          startcolor={data.details.gradient.startcolor}
          endcolor={data.details.gradient.endcolor}
          imageSrc={data.details.banner.localFile?.publicURL}
          title={data.title}
          size={imageSize}
        />
        <header>
          <h2>
            <span>{data.title}</span>
          </h2>
          <time dateTime={new Date(data.date).toISOString()}>
            Publié le {new Date(data.date).toLocaleDateString()}
          </time>
        </header>
        <div
          className="mt-3"
          dangerouslySetInnerHTML={{
            __html: data.excerpt,
          }}></div>
      </Link>
      {noClickTag ?
        <span className="mf-tag badge badge-pill badge-light">{category.name}</span> :
        <Link to={`/blog?tag=${category.slug}`} className="mf-tag badge badge-pill badge-light">{category.name}</Link>
      }

    </article>
  )
}
