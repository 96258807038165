import * as React from "react"
import { getImage, GatsbyImage } from "gatsby-plugin-image"

export default function Reference({ data, image }) {
  return (
    <div className={`row mf-reference mf-reference--${data.class}`}>
      <div className="col-lg-4 col-md-5 col-sm-6">
        <div className="mf-reference__image">
          <h4 className="d-block d-sm-none mf-reference__title">
            {data.title} <span>{data.year}</span>
          </h4>
          <GatsbyImage
            image={getImage(image.node)}
            width={350}
            height={241}
            alt={"Screenshot - " + data.title}
          />
          <span className="mf-reference__back"></span>
        </div>
      </div>

      <div className="col-lg-8 col-md-7 col-sm-6 mf-reference__content">
        <h4 className="d-none d-sm-block mf-reference__title">
          {data.title} <span>{data.year}</span>
        </h4>
        <p className="mf-reference__content">{data.intro}</p>

        <div className="mf-reference__responsibilities">
          <h5>Responsabilités</h5>
          <ul>
            {data.responsibilities.map((resp) => (
              <li key={resp}>{resp}</li>
            ))}
          </ul>

          <h5>Technologies</h5>
          <ul>
            {data.technologies.map((tech) => (
              <li key={tech}>{tech}</li>
            ))}
          </ul>
        </div>

        <a
          href={data.link}
          target="_blank"
          rel="noreferrer"
          className="mt-4 btn btn-sm btn-outline-dark">
          {data.textLink ? data.textLink : "Visiter le site"}
        </a>
      </div>
    </div>
  )
}
